
import { Component, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { IPagination } from "@/types";
import {
  IPriceTagSupplier,
  IPriceTagSupplierListRequest
} from "@/types/price_tag_supplier";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //price_tag_supplier
  @Action("price_tag_supplier/adminGetList")
  public getList!: (request: IPriceTagSupplierListRequest) => boolean;

  @Getter("price_tag_supplier/list")
  public supplier_product_list!: IPriceTagSupplier[];

  @Getter("price_tag_supplier/pagination")
  public pagination!: IPagination<IPriceTagSupplier[]>;

  @Mutation("price_tag_supplier/clear")
  public clear!: () => void;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getList(this.requestParams);
  }

  //
  // variables
  //
  public headers = [
    { text: "サプライヤ品番", value: "supplier_pn", sortable: false },
    { text: "サプライヤ名", value: "supplier_name", sortable: false }
  ];

  // パラメータ定義
  public requestParams: IPriceTagSupplierListRequest = {
    page: 1,
    price_tag_id: 0
  };

  //
  // methods
  //
  private async created() {
    this.clear();
    this.queryToParams();
    await this.getList(this.requestParams);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const routeQuery = this.$route.query;
    const routeParams = this.$route.params;

    this.requestParams = {
      page: routeQuery.page ? Number(routeQuery.page) : 1,
      price_tag_id: routeParams.price_tag_id
        ? Number(routeParams.price_tag_id)
        : 0
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.requestParams.page = 1;
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          page: this.requestParams.page ? String(this.requestParams.page) : "1"
        }
      })
      .catch(() => {});
  }

  // 行をクリックした際の挙動
  public rowClick(price_tag_supplier: IPriceTagSupplier) {
    this.$router.push(
      `/admin/price_tag_supplier/${price_tag_supplier.id}/edit`
    );
  }
}
