
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import PriceTagSupplierList from "@/components/admin/list/PriceTagSupplierList.vue";
@Component({
  components: { PriceTagSupplierList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  public price_tag_id = 0;

  public created() {
    this.price_tag_id = Number(this.$route.params.price_tag_id);
  }
}
